<template>
  <div>
    <div class="Home">
      <div class="Home_top">
        <div class="Home_top_list">
          <div style="display:flex">
            <img src="@/assets/img/zongtousu_icon.png" style="width:16px;" alt="">
            <span style="margin-left:5px;color: #666666;">总投诉</span>
          </div>
          <div class="Home_top_num">{{Counts.complaint_count}}</div>
        </div>
        <div class="Home_top_list">
          <div style="display:flex">
            <img src="@/assets/img/yiwancheng_icon.png" alt="">
            <span style="margin-left:5px;color: #666666;">已完成</span>
          </div>
          <div class="Home_top_num">{{Counts.complete_count}}</div>
        </div>
        <div class="Home_top_list">
          <div style="display:flex">
            <img src="@/assets/img/weiwancheng_icon.png" alt="">
            <span style="margin-left:5px;color: #666666;">未完成</span>
          </div>
          <div class="Home_top_num">{{Counts.unfinished}}</div>
        </div>
      </div>
      <div class="Home_content">
        <div style="font-size: 18px;color: #333333;">最新消息</div>
        <div class="Home_content_list" v-for="(item,index) in NewList" :key="index">
          <div class="Home_content_list_l" v-if="item.msg =='评论了你'">
            <span style="color: #999999;font-size:14px;margin-right:8px;">{{item.create_time}}</span>
            <span>您的投诉<span style="color:#FE9025;cursor: pointer;" @click="Go_Detas(item.userComplaints.id)">《{{item.userComplaints.statement_title}}》</span></span>
            <span>收到新评论</span>
          </div>
          <div class="Home_content_list_l" v-if="item.msg =='点赞了你'">
            <span style="color: #999999;font-size:14px;margin-right:8px;">{{item.create_time}}</span>
            <span>有人赞了您的投诉<span style="color:#FE9025;cursor: pointer;" @click="Go_Detas(item.complaintCollection.id)">《{{item.complaintCollection.statement_title}}》</span></span>
          </div>
          <div class="Home_content_list_l" v-if="item.msg =='回复了你'">
            <span style="color: #999999;font-size:14px;margin-right:8px;">{{item.create_time}}</span>
            <span>您的投诉<span style="color:#FE9025;lcursor: pointer;" @click="Go_Detas(item.userComplaints.id)">《{{item.userComplaints.statement_title}}》</span></span>
            <span>收到新回复</span>
          </div>
          <div class="Home_content_list_l" v-if="item.type=='2'">
            <span style="color: #999999;font-size:14px;margin-right:8px;">{{item.create_time}}</span>
            <span>您的投诉<span style="color:#FE9025;cursor: pointer;" @click="Go_Detas(item.userComplaints.id)">《{{item.userComplaints.statement_title}}》</span></span>
            <span>已通过审核</span>
          </div>
        </div>
      </div>
      <div class="Home_bottom">
        <div style="font-size: 18px;color: #333333;margin-top:35px;">热门投诉</div>
        <div class="Hot">
          <div class="Hot_List" v-for="(item, index) in List" :key="index" @click="Go_xq(item.id)">
            <div class="Hot_List_top">
              {{ item.statement_title }}
            </div>
            <div class="Hot_List_content">
              <div class="Hot_List_content_top">
                <span>[投诉对象]</span>
                <span>{{ item.business_name }}</span>
              </div>
              <div class="Hot_List_content_top">
                <span>[投诉理由]</span>
                <span>{{ item.complaint_reason }}</span>
              </div>
            </div>
            <div class="Hot_List_bottom">
              <div class="Hot_List_bottom_left">
                <div>
                  <img src="@/assets/img/baoguang_icon.png" alt="">
                  <span>{{item.pv_count}}</span>
                </div>
                <div>
                  <img src="@/assets/img/dianzan_icon.png" alt="">
                  <span>{{ item.like_num }}</span>
                </div>
              </div>
              <div class="Hot_List_bottom_right">
                <div v-if="item.status == 2" class="is_Hot">已完成</div>
                <div v-if="item.status == 1" class="is_Hot1">处理中</div>
              </div>
            </div>
            <div class="Hot_bottom">
              <div class="Hot_bottom_left">
                <img :src="item.headimgurl" alt="">
                <span>{{ item.nickname }}</span>
              </div>
              <div class="Hot_bottom_right">
                {{item.create_time.slice(0,10)}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Homes">
        <div class="Homes_left">
          <div class="Homes_box">
            <div class="Homes_title">榜单</div>
            <div class="Homes_List" v-for="(item,index) in K_List" :key="index" @click="Go_qdzg(item.contentid)">
              <a :href="item.url" style="display:flex;" target="_blank">
                <img :src="item.img" alt="">
                <div class="Homes_List_right">
                  <div>{{item.title}}</div>
                  <div style="margin-left:188px;">{{item.created}}</div>
                </div>
                </a>
            </div>
          </div>
        </div>
        <div class="Homes_right">
            <div class="Homes_box">
            <div class="Homes_title">平台公告</div>
            <div class="Homes_List" style="justify-content: space-between;align-items: center;" v-for="(item,index) in Platform" :key="index">
                  <div class="Homes_right_ovfl">{{item.title}}</div>
                  <div>{{item.create_time.slice(0,10)}}</div>
            </div>
            <van-pagination v-model="currentPage" prev-text="<" next-text=">" v-if="Platform.length>10" @change="fenye" :page-count="yenum" mode="simple" />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5';
import Axios from "axios";
import {complaint_count,complaint_msg,notice_list} from "../../api/api"
export default {
   metaInfo(){
     return{
        title: '个人中心',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      List: [],
      currentPage:1,
      Counts:'',
      NewList:[],
      K_List:[],
      Platform:[],
      yenum:'',   //页数
    };
  },
  methods: {
    // 平台公告
    Notice_list(){
      notice_list({type:0,}).then((res) => {
          console.log(res);
          this.yenum=res.data.data.last_page;
          this.Platform=res.data.data.data;
        });
    },  
    fenye(e){
      //console.log(e);
      notice_list({type:0,page:e}).then((res) => {
          //console.log(res.data.data.data);
          this.Platform=res.data.data.data;
        });
    },
    // 消息跳转详情页
    Go_Detas(id){
      //console.log(id);
      this.$router.push({ name: "Details", query: { id: id } });
    },
    // 跳转详情页面
    Go_xq(id) {
      //console.log(id);
      this.$router.push({ name: "Details", query: { id: id } });
    },
    Complaint_count(){
      complaint_count({type:1}).then((res) => {
          //console.log(res);
          this.Counts=res.data.data;
        });
    },
    // 消息
    Complaint_msg(){
      complaint_msg({}).then((res) => {
          console.log(res.data.data);
          this.NewList=res.data.data;
        });
    },
    K_get_list(){
      Axios({
        method: "get",
        url: "https://weixin.qudong.com/api/author/k_get_list",
        params: {},
      }).then((res) => {
        //console.log(res.data.data);
        this.K_List=res.data.data.slice(-5);
      });
    },
    complaint_index() {
      Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/complaint_index",
        params: {
          type: 0,
          key: md5("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB"),
        },
      }).then((res) => {
        //console.log(res.data.data);
        this.List = res.data.data.data.slice(0,2);
      });
    },
  },
  mounted() {
      let that = this;
      that.complaint_index();
      that.Complaint_count();
      that.Complaint_msg();
      that.K_get_list();
      that.Notice_list();
    },
};
</script>

<style scoped>
/* 下面 */
.Homes{
  margin-top:15px;
  width: 940px;
  background: #fff;
  padding: 30px 0px 40px 30px;
  margin-left: 20px;
  display:flex;
}
.Homes_left{
  width:440px;
  margin-right:20px;
}
.Homes_List_right{
  display:flex;
  margin-left:10px;
  flex-direction: column;
  justify-content: space-between;
}
.Homes_List{
  padding:15px;
  display:flex;
  cursor: pointer;
}
.Homes_right_ovfl{
width: 310px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
.Homes_List img{
  width: 130px;
height: 86px;
}
.Homes_title{
  padding:8px 15px 8px;
  border-bottom:1px solid #E8E8E8;
}
.Homes_right{
  width:440px;
}
.Homes_box{
border: 1px solid #E8E8E8;
padding-top:10px;
border-radius: 6px;
height:630px;
}
.Home {
  width: 940px;
  background: #fff;
  padding: 30px 0px 20px 30px;
  margin-left: 20px;
}
.Home_top {
  display: flex;
  justify-content: space-around;
}
.Home_top_list {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Home_top_list img {
  width:18px;
  height:18px;
  display: inline-block;
}
.Home_top_num {
  font-size: 32px;
  margin-top: 15px;
  font-weight: bold;
  color: #333333;
}
.Home_content_list_l {
  margin-top: 20px;
}
.Hot {
  margin-top: 20px;
  width: 900px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Hot_List {
  width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
border: 1px solid #E8E8E8;
}
.Hot_List_top {
  margin-bottom: 15px;
}
.Hot_List_content_top span:nth-child(1) {
  color: #1f72d9;
}
.Hot_List_content_top span:nth-child(2) {
  color: #666666;
}
.Hot_List_bottom {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 15px;
}
.Hot_List_content_top {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Hot_List_bottom_left {
  display: flex;
  align-items: center;
}
.Hot_List_bottom_left span {
  color: #999999;
  font-size: 14px;
}

.Hot_List_bottom_left div {
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.Hot_List_bottom_left div:nth-child(1) img {
  width: 24px;
  height: 14px;
  margin-right: 5px;
}
.Hot_List_bottom_left div:nth-child(2) img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.is_Hot {
  background: #d2e3f7;
  opacity: 0.7;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #1f72d9;
  font-weight: bold;
}
.is_Hot1 {
  background: #ffe9d3;
  opacity: 0.7;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #fea962;
  font-weight: bold;
}
.Hot_bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Hot_bottom_left {
  display: flex;
  align-items: center;
}
.Hot_bottom_left img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}
.Hot_bottom_right {
  font-size: 14px;
  color: #999999;
}
>>> .van-pagination{
  width:200px;
  margin-left:120px;
  margin-top:15px;
}
>>> .van-pagination__item{
  background:none;
  font-size:27px;
  opacity: unset;
}
>>> .van-pagination--simple .van-pagination__next::after, .van-pagination--simple .van-pagination__prev::after{
  border-width:0;
}
>>> .van-pagination__item::after{
  border-width:0;
}
>>> .van-pagination--simple .van-pagination__next::after, .van-pagination--simple .van-pagination__prev::after{
  border-width:0;
}
</style>